import { linkList } from "../../../constant/links";
import React from "react";

const MappingMenuRole = ({user}) => {
	return [
    {
      name: 'Project',
      label: 'PROJECT',
      role: ['admin', 'master_admin', 'master_client'],
      link: linkList.app.top_page
    },
    {
      name: 'Profile',
      label: 'PROFILE',
      role: ['admin'],
      //update later extra class
      // extra_class: '{% if user.user_creator.exists and user.user_creator.first.last_version %} creator-profile-new {% if  user.user_creator.first.last_version.owner_id == user.pk %}owner-creator-profile-new{% else %}checker-creator-profile-new{% endif %}{% endif %}',
      link: user?.profile_link
    },
    {
      name: 'Gallery',
      label: 'GALLERY',
      role: ['curator', 'master_client'],
      link: linkList.app.creator_index
    },
    {
      name: 'Account',
      label: 'ACCOUNT',
      role: ['master_admin'],
      link: linkList.account.accounts_list
    },
    {
      name: 'Account',
      label: 'ACCOUNT',
      role: ['curator'],
      link: linkList.account.curator_setting
    },
    {
      name: 'Mileage',
      label: 'MILEAGE',
      role: ['master_admin'],
      link: linkList.mileage.index
    },
    {
      name: 'UpdateInfo',
      label: 'UPDATE INFO',
      role: ['master_admin'],
      link: linkList.app.updateinfo
    },
    {
      name: 'Collection',
      label: <span className="icon icon--sicon-bookmark fa-bookmark icon-bookmark-navbar"></span>,
      role: ['admin', 'master_client'],
      link: linkList.app.get_bookmarked
    }
  ];
}

export default MappingMenuRole;
