import React from "react";
import function1Img from '../../../assets/images/function1@2x.png';
import function2Img from '../../../assets/images/function2@2x.png';

const FunctionBlock = () => {
  return <aside style={{
    background: '#ffffff'
    }}>
    <div className="function wrapper">
      <div className="function-item"><img src={function1Img} alt="function1"/>
        <h5>スマートコントラクト</h5>
        <p>プロジェクト内の契約締結を完全オンライン化。全スタッフとの契約が緻密に紐付き、締結できるので、多彩なスペシャリストとの協働体制を実現します。</p>
      </div>
      <div className="function-item"><img src={function2Img} alt="function2"/>
        <h5>スタッフロール</h5>
        <p>プロジェクトに参画したスタッフ陣が一目瞭然。</p>
        <p>制作するほど、あなたのお気に入りスタッフが精査されていくので、ドリームチームでのスタッフィングが叶います。</p>
      </div>
    </div>
  </aside>
}

export default FunctionBlock;