import React from 'react';
import TopPageLayout from './index';

const routes = [
  {
    path: "/top",
    exact: true,
    component: <TopPageLayout/>
  }
]

export default routes;