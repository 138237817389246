import { configureStore } from '@reduxjs/toolkit'
import profileReducer from './actions/profile/profile'
import { setupListeners } from '@reduxjs/toolkit/query'
import { profileApi } from './services/auth'
import { listProjectApi } from './services/project'

export const store = configureStore({
  reducer: {
    [profileApi.reducerPath]: profileApi.reducer,
    [listProjectApi.reducerPath]: listProjectApi.reducer,
    profile: profileReducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(profileApi.middleware, listProjectApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)