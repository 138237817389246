import React from 'react';
import HomepageLayout from './index';

const routes = [
  {
    path: "/",
    exact: true,
    component: <HomepageLayout/>
  },{
    path: "",
    exact: true,
    component: <HomepageLayout/>
  }
]

export default routes;