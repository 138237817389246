import React, { useState } from "react";
import NavBarBlock from "../../components/header";
import { isMobile } from "react-device-detect";
import { linkList } from "../../constant/links";
import { useGetProfileByNameQuery } from "../../app/services/auth";
import { useGetProjectQuery } from "../../app/services/project";
import ShareModal from "./components/modal/ShareModal";
import ProcessingSceneModal from "./components/modal/ProcessingSceneModal";
import ProductBanner from "./components/project-banner/index";

const TopPageLayout = () => {
	const { data, error, isLoading } = useGetProfileByNameQuery('')
	let { data: projectData, error: errorPJ, isLoading: isLoadingPJ } = useGetProjectQuery({
		page: 1,
		item_per_page: 10
	})
	const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isProcessingSceneModalOpen, setProcessingSceneModalOpen] = useState(false);

	let user = data?.result

	console.log({
		projectData, errorPJ, isLoadingPJ
	})

	return <div>
		<NavBarBlock/>
		<main className={`owner-top ${isMobile ? "on-mobile" : ""}`}>
			<div className="container">
				<div className="sprojects">
					{['master_client','admin'].includes(user?.role) ? 
					<div className="sprojects-add-project">
						<a className="sprojects-add-project__content" href={
							user?.role === "master_client" ? linkList.app.direct_create_offer : linkList.app.artist_create_product
						}>
							<span className="material-symbols-rounded" style={{
								fontSize: '32px'
							}}>add_circle</span>
							<div className="sprojects-add-project__text bodytext-11">
								{user.role === "master_client" ? "プロジェクトを相談" : "プロジェクトを作成"}
							</div>
						</a>
					</div> : <></>}
					<div className="sprojects-filter">
						<div className="form-check custom-switch">
							<label className="form-check-label">
								<div className="form-check-group">
									<input className="form-check-input switch-checkbox" type="checkbox" name="switch-chapter" id="switch-chapter"/><span className="switch-slider"></span>
								</div>
								<span className="switch-label" >{'See also the archive'}</span>
							</label>
						</div>
						<div className="video-order">
							<div className="video-order-type video-order__asc"><i className="icon icon--sicon-asc"></i>
						</div>
						<div className="video-order-type video-order__desc active"><i className="icon icon--sicon-desc"></i>
							</div>
						</div>
						<div className="video-order-by">
							<div className="sselect-wrapper select-white">
								<select className="select" id="video-orderby" placeholder="Select Here" data-search="false"
									data-search-text="Enter the keyword">
									<option value="modified">最終更新日</option>
									<option value="created">開始日</option>
									<option value="rating">評価</option>
									<option value="order">手動</option>
								</select>
							</div>
						</div>
					</div>


					<div className="project-list" data-total-page="{{ total_page }}">
						<>
							{projectData?.projects ? projectData?.projects.map((project) => {
								// const productUser = getProductUser(user, project); // Replace with actual logic to get product user
								const hasNotification = false;//checkNotificationProduct(user, project); // Replace with actual logic to check notifications
								const isPC = !isMobile; // Implement this function to check if the device is a PC

								return (
									<div
										key={project.product_id}
										className={`project-item-handle ${!hasNotification ? "no-notification" : ""}`}
										data-product-user-id={project.product_id}
									>
										<a
											href={
												user.role === "master_admin"
													? `/app/message_owner/${project.product_id}/` // Update this with the correct URL structure
													: `/app/top_project_detail/${project.product_id}/` // Update this with the correct URL structure
											}
										>
											<div
												className={`project-item ${!isPC ? "on-mobile" : ""}`}
												data-project-id={project.product_id}
											>
												<ProductBanner project={project} user={user} typePage={'top_page'} projectList={true} isPC={isPC} showStaff={true} />
											</div>
										</a>
									</div>
								);
							}) : <></>}
						</>

					</div>
					<div className="project-member-setting-modal modal fade" id="project-member-setting" role="dialog" style={{display: 'none'}}>

					</div>
					<ShareModal isOpen={isShareModalOpen} onClose={() => setShareModalOpen(false)} />
					<ProcessingSceneModal isOpen={isProcessingSceneModalOpen} onClose={() => setProcessingSceneModalOpen(false)} />
				</div>
			</div>
		</main>
	</div>
}

export default TopPageLayout;
