import React from "react";
import { linkList } from "../../constant/links";

const UnauthorizedHeader = () => {
  return <div className="sheader d-block-header">
    <div className="sheader-container">
      <div className="smenu-pc">
        <div className="mcontainer">
          <div className="sheader-pc">
            <div className="sheader-pc__left">
              <div className="sheader-pc-logo">
                <a className="logo" href="#">
                </a>
              </div>
            </div>
            <div className="sheader-pc__right">
              <div className="sheader-links">
                <a className="sheader-link" href={linkList.app.creator_index} dataShow="gallery">GALLERY</a>
              </div>
              <div className="sheader-info">
                <a className="sheader-account-link" href={linkList.account.accounts_login}>
                  <div className="avatar avatar--icon avatar--32 avatar--round" id="account-link">
                    <div className="avatar-icon" style={{color: '#000'}}>
                      <i class="bi bi-person-circle"></i>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default UnauthorizedHeader;