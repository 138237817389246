import React from "react";
import concept1Img from '../../../assets/images/concept1.png';
import concept2Img from '../../../assets/images/concept2.png';
import concept3Img from '../../../assets/images/concept3.png';
import mapNewImg from '../../../assets/images/map_new.png';

const ArticleBlock = () => {
  return <article>
    <div className="concept wrapper">
      <div className="concept-text concept-text-right">
        <h3>動画を送るだけ！</h3>
        <p>具体的な「音」のイメージが湧いていなくても大丈夫。絵コンテPDFやラフ動画だけでも、最適プランをご提案いたします。無料のお見積もりはサインアップから。<br/></p>
      </div>
      <div className="concept-image"><img src={concept1Img} alt="article1-image"/></div>
    </div>
    <div className="concept wrapper concept-text-left">
      <div className="concept-image-reverse"><img src={concept2Img} alt="article2-image"/></div>
      <div className="concept-text">
        <h3>チェックもカンタン！</h3>
        <p>お知らせが届いたら、「音」つき動画をチェック。調整したいところをコメントすると、心ゆくまで、やりとりを重ねてゆけます。検収もハートをクリックするだけの手軽さです。
          <br/>
          <img src={mapNewImg} alt="checkback map"/>
        </p>
      </div>
      </div>
      <div className="concept wrapper concept-text-right">
        <div className="concept-text">
          <h3>セキュリティ万全！</h3>
          <p>制作中のチェックは、プロジェクトごとのメンバー招待制。<br/>
          権限設定も自在なので、まるでヴィラのように、快適安全なコミュニケーションを実現します。IP制限。マシンIDチェックも備えているので、機密性の高い内容も安心です。</p>
        </div>
        <div className="concept-image"><img src={concept3Img} alt="article3-image"/></div>
      </div>
  </article>
}

export default ArticleBlock;