import React from "react";
import { useGetProfileByNameQuery } from "../../app/services/auth";
import UnauthorizedHeader from "./UnauthorizedHeader";
import AuthorizedHeader from "./AuthorizedHeader";
import './style.scss';
import '../../pages/home/index.scss';

const NavBarBlock = () => {
  // Using a query hook automatically fetches data and returns query values
  const { data, error, isLoading } = useGetProfileByNameQuery('')
  // Individual hooks are also accessible under the generated endpoints:


  if (isLoading || !data?.result?.is_active){
    return <UnauthorizedHeader/>
  }else{
    return <AuthorizedHeader user={data?.result}/>
  }
}

export default NavBarBlock;