import logoCapcomImg from '../assets/images/logo_capcom@2x.png';
import logoUniversalImg from '../assets/images/logo_universal@2x.png';
import logoTakaoImg from '../assets/images/logo_takao@2x.png';
import logoDaiichiImg from '../assets/images/logo_daiichi@2x.png';
import logoNewginImg from '../assets/images/logo_newgin@2x.png';
import logoMaruhonImg from '../assets/images/logo_maruhon@2x.png';
import logoIgnitionpointImg from '../assets/images/logo_ignitionpoint@2x.png';
import logoSceneImg from '../assets/images/logo_scene@2x.png';
import logoKokooImg from '../assets/images/logo_kokoo.png';
import logoPKraftImg from '../assets/images/logo_p-kraft@2x.png';
import logoStudioRfImg from '../assets/images/logo_studiorf@2x.png';
import logoLtGameJapanImg from '../assets/images/logo_ltgamejapan.png';
import logoItscomImg from '../assets/images/logo_itscom.png';
import logoSundenshiImg from '../assets/images/logo_sundenshi@2x.png';
import logoBabeltimeImg from '../assets/images/logo_babeltime.png';
import logoElfInImg from '../assets/images/logo_elf-in.png';
import logoMahouImg from '../assets/images/logo_mahou@2x.png';
import logoDoImg from '../assets/images/logo_do@2x.png';
import logoOribanaImg from '../assets/images/logo_oribana@2x.png';
import logoToranekoImg from '../assets/images/logo_toraneko.png';
import logoPintoImg from '../assets/images/logo_pinto@2x.png';
import logoAceoneImg from '../assets/images/logo_aceone@2x.png';
import logoHsagImg from '../assets/images/logo_hsag@2x.png';

const logoList = 
[
  {
    "link": "http://www.capcom.co.jp/",
    "src": logoCapcomImg,
    "alt": "capcom"
  },
  {
    "link": "https://www.universal-777.com/",
    "src": logoUniversalImg,
    "alt": "universal"
  },
  {
    "link": "http://www.takao.gr.jp/",
    "src": logoTakaoImg,
    "alt": "takao"
  },
  {
    "link": "https://daiichi777.jp/",
    "src": logoDaiichiImg,
    "alt": "daiichi"
  },
  {
    "link": "http://www.newgin.co.jp/",
    "src": logoNewginImg,
    "alt": "newgin"
  },
  {
    "link": "https://maruhon-kogyo.co.jp/",
    "src": logoMaruhonImg,
    "alt": "maruhon"
  },
  {
    "link": "https://www.ignitionpoint-inc.com",
    "src": logoIgnitionpointImg,
    "alt": "Ignision Point"
  },
  {
    "link": "http://www.scene-tokyo.com/",
    "src": logoSceneImg,
    "alt": "scene"
  },
  {
    "link": "https://www.kokoo.co.jp/",
    "src": logoKokooImg,
    "alt": "kokoo"
  },
  {
    "link": "https://www.p-kraft.com/",
    "src": logoPKraftImg,
    "alt": "p-kraft"
  },
  {
    "link": "http://studio-rf.com/",
    "src": logoStudioRfImg,
    "alt": "studiorf"
  },
  {
    "link": "http://lt-japan.com/index.html",
    "src": logoLtGameJapanImg,
    "alt": "LT Game Japan"
  },
  {
    "link": "https://www.itscom.co.jp/",
    "src": logoItscomImg,
    "alt": "itscom"
  },
  {
    "link": "https://www.sun-denshi.co.jp/",
    "src": logoSundenshiImg,
    "alt": "sunsoft"
  },
  {
    "link": "https://www.babeltime.com/",
    "src": logoBabeltimeImg,
    "alt": "babeltime"
  },
  {
    "link": "https://elf-in.com/",
    "src": logoElfInImg,
    "alt": "elf-in"
  },
  {
    "link": "http://www.mahou.co.jp/",
    "src": logoMahouImg,
    "alt": "mahou"
  },
  {
    "link": "https://www.d-o-inc.co.jp/",
    "src": logoDoImg,
    "alt": "do"
  },
  {
    "link": "https://olibanu.com/",
    "src": logoOribanaImg,
    "alt": "oribana"
  },
  {
    "link": "https://tora-neko.co.jp/",
    "src": logoToranekoImg,
    "alt": "toraneko"
  },
  {
    "link": "https://pinto7.com/",
    "src": logoPintoImg,
    "alt": "pinto"
  },
  {
    "link": "http://aceone.co.jp/",
    "src": logoAceoneImg,
    "alt": "aceone"
  },
  {
    "link": "http://www.hsag.co.jp/",
    "src": logoHsagImg,
    "alt": "hsag"
  }
]

export default logoList;