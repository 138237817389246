import React from 'react';
import { colorSetting } from '../../../../constant/color';
import { fontSetting } from '../../../../constant/font';
// import ProgressBar from './ProgressBar'; // Import your ProgressBar component
// import Avatar from './Avatar'; // Create an Avatar component to handle user avatars

const ProjectBanner = ({ project, user, typePage = 'top_page', pageDetail }) => {
  const notification = true;//user.checkNotificationProduct(project);
  const image = project.imageResized || project.image;
  const color = project.color_setting ? colorSetting[project.color_setting - 1] : {} ;
  const font = project.font_setting ? fontSetting[project.font_setting - 1] : {};

  return (
    <div className={`pbanner ${!notification && typePage === 'top_page' ? 'no-notification' : ''}`} data-project-role={user.position}>
      {(!image) ? (
        <div className={`pbanner__image u-row u-align-center ${color?.code || 'banner-default'}`} style={{ width: '100%', height: '75px', ...color?.css_code }}>
          <div style={{ padding: '0px 16px', ...font?.css_code, color: color?.text_color_css }}>
            {project.get_name_in_page_title}
          </div>
        </div>
      ) : (
        <div className="pbanner__image" style={{ backgroundImage: `url(${image})` }} />
      )}

      {/* <div className="pbanner__top">
        {typePage === 'top_page_admin' && (
          <div className="bdropdown">
            <i className="icon icon--sicon-prev" />
          </div>
        )}
        <div className="pbanner__members">
          <div className="pbanner__user-list" data-toggle="modal" data-target="#modal-member-manage">
            {project.getOwners().map((owner, index) => {
              if (index < (user.isMobile() ? 7 : 30)) {
                return (
                  <Avatar key={owner.pk} user={owner} />
                );
              }
              return null;
            })}
            {project.getMembers().map((member, index) => {
              if (index < (user.isMobile() ? 7 : 15)) {
                return (
                  <Avatar key={member.pk} user={member} />
                );
              }
              return null;
            })}
            {!project.getOwners().length && !project.getMembers().length && project.getMemberInviting() && (
              <div className="pbanner__user">
                <div className="avatar avatar--image avatar--24 avatar--square background-avt">
                  <div className="avatar-image button--show-member" style={{ backgroundImage: `url(/static/images/icon_user.svg)` }} />
                </div>
              </div>
            )}
          </div>
          {user.isEditableDescription(project) && (
            <span className="pbanner__user-btn" data-toggle="modal" data-target="#modal-invite-member" style={{ marginBottom: '8px' }}>
              <i className="icon icon--sicon-plus" />
            </span>
          )}
        </div>
      </div>

      <div className="pbanner__progress">
        <ProgressBar project={project} typePage={typePage} />
      </div>

      {typePage === 'top_page' && pageDetail === 'detail' ? (
        <div className={`pbanner-info ${user.role === 'master_client' && !project.checkHasSceneInProject ? 'hide' : ''}`}>
        </div>
      ) : (
        <div className="sproject-meta">
          <div className="u-row-between u-w100 u-ptb8 u-gap8">
            <div className="u-row u-gap8">
              <span className="bodytext-13 u-text-blue">{project.getCurrentHeartRate()}%</span>
              <span className="bodytext-13 u-text-light-gray cannot-check" data-max-scene={project.maxScene}>
                ({project.currentHeart}/{project.maxScene})
              </span>
              {user.role === 'admin' || user.role === 'master_client' ? (
                <span className="c-budge u-row-center">
                  {project.countBatchNumberProject(user) > 0 && project.countBatchNumberProject(user) < 100 ? project.countBatchNumberProject(user) : '99+'}
                </span>
              ) : null}
            </div>
            <div className="sproject-date-time sproject-project-time">
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ProjectBanner;
