import React from "react";
import { getListMenuFromUser } from "./common/utils";
import { linkList } from "../../constant/links";

const AuthorizedHeader = ({
  user
}) => {
  let listMenu = getListMenuFromUser({user});

  return <div className="sheader d-block-header">
    <div className="sheader-container">
      <div className="smenu-pc">
        <div className="mcontainer">
          <div className="sheader-pc">
            <div className="sheader-pc__left">
              <div className="sheader-pc-logo">
                <a className="logo" href="#">
                </a>
              </div>
            </div>
            <div className="sheader-pc__right">
              <div className={`sheader-links ${user.role === 'master_admin' ? 'nav-master-admin' : ''}`}>
                {
                  listMenu.map((menuInfo) => {
                    return <a className={`sheader-link text-link ${menuInfo?.extra_class}`} href={menuInfo.link} dataShow={menuInfo.name}>{menuInfo.label}</a>
                  })
                }
              </div>
              <div className="sheader-info">
                <a className="sheader-account-link" href="#">
                  <div className={user.role == 'admin' ? 'mileage-popup' : ''} id="account-link">
                      <img className="c-avatar40" src={user.medium_avatar}/>
                  </div>
                </a>
                <div className="sheader-dropdown bdropdown">
                  <a className="bdropdown-toggle dropdown-toggle" href="#" role="button" id="mthread-id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="icon icon--sicon-dropdown"></i>
                  </a>
                  <div className="bdropdown-menu dropdown-menu" aria-labelledby="mthread-id">
                    {
                      user.role != 'admin' ? <>
                        <a className="bdropdown-item" href={`${linkList.app.accounts_update}/${user.id}`}>アカウント情報</a>
                        <a className="bdropdown-item" href={`${linkList.app.accounts_setting}/${user.id}`}>利用設定</a>
                        {
                          user.role == 'master_client' ? <>
                            <a className="bdropdown-item" href={`${linkList.account.payment_info}/${user.id}`}>クレジットカード情報を設定</a>
                            <a className="bdropdown-item" href="https://soremo.notion.site/Owner-Help-Center-76d42490929b46909d60f688413ac9a1" target="_blank">ヘルプセンター</a>    
                          </> : user.role == 'master_admin' ? <>
                            <a className="bdropdown-item" href={`${linkList.payment.master_admin}`}>検収状況</a>
                            <a className="bdropdown-item" href="https://soremo.notion.site/Partner-Help-Center-a294ba282ad9438a968a3cb9e1ef7c77" target="_blank">ヘルプセンター</a>
                          </> : <>
                            <a className="bdropdown-item" href="https://soremo.notion.site/Partner-Help-Center-a294ba282ad9438a968a3cb9e1ef7c77" target="_blank">ヘルプセンター</a>
                          </>
                        }
                      </> : <>
                        {
                          user?.creator_id ? <>
                            <a className="bdropdown-item" href={`${linkList.account.creator_info}/${user.creator_id}`}>アカウント情報</a>
                            <a className="bdropdown-item" href={`${linkList.account.creator_setting}/${user.creator_id}`}>利用設定</a>
                          </> : <>
                            <span>{"No account information available"}</span>
                            <span>{"No usage setting available"}</span>
                          </>
                        }
                        <a className="bdropdown-item" href={linkList.payment.payment_current_artist}>ウォレットの残高</a>
                        <a className="bdropdown-item" href={`${linkList.account.payment_info}/${user.id}`}>クレジットカード情報を設定</a>
                        <a className="bdropdown-item" href="https://soremo.notion.site/Partner-Help-Center-a294ba282ad9438a968a3cb9e1ef7c77" target="_blank">ヘルプセンター</a>
                      </>
                    }
                    <a className={`bdropdown-item btn-logout ${user.role == 'admin' ? 'is-admin' : ''}`} href={linkList.account.logout}>サインアウト</a>
                  </div>
                </div>
                  {/* {% if not hide_milaeage_popup %}
                      {% include 'components/_modal.html' with position="top-right" body_html="_modal_mileage_popup_body.html" modal_id="modal_mileage_popup" modal_zindex="2000" %}
                  {% endif %} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default AuthorizedHeader;