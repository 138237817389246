import React from "react";
import logoList from "../../../constant/logo";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination } from 'swiper/modules';

const ListLogo = () => {
  return <div className="list-logo">
    <div className="wrapper">
      <Swiper
        modules={[Autoplay, Pagination]}
        loop={true}
        speed={500}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        spaceBetween={50}
        slidesPerView={5}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {logoList.map((logo) => {
          return <SwiperSlide>
            <div className="logo-item">
              <a href={logo.link} target="_blank" rel="noreferrer">
                <img src={logo.src} alt={logo.alt}/>
              </a>
            </div>
          </SwiperSlide>
        })}
      </Swiper>
    </div>
  </div>
}

export default ListLogo;