import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routeSchema from "./pages/routes";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { ConfigProvider, App } from 'antd';


const Application = () => {
  return <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#FF7F27',
        borderRadius: 4,
        colorBgContainter: '#202020'
      }
    }}
  >
    <App>
      <Router>
        <Routes>
          {
            routeSchema.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} exact={route.exact}/>
            ))
          }
        </Routes>
      </Router>
    </App>
  </ConfigProvider>
}

export default Application;
